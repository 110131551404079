import React from 'react'
import logo from '../../assets/Home/betbodylogo.png';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <div className="tracking-widest mx-auto flex flex-col md:flex-row items-center justify-between bg-[#161318] text-white p-5 md:p-3">
                <div className="flex items-center mb-4 md:mb-0">
                    <img
                        src={logo}
                        alt="baisaly-logo"
                        className='w-auto h-16 md:h-20'
                    />
                    <div className="text-center md:text-left mb-4 md:mb-0">
                        {currentYear} © <span className=''>Bet-Buddy</span>.
                    </div>
                </div>
                <div>
                    <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-8 text-center">
                        <div>REWARDS</div>
                        <div>Raffles</div>
                        <div>Leaderboard</div>
                        <div>Store</div>
                    </div>
                    <div className="text-center md:text-right mt-4">
                        <div className="text-sm d-none d-sm-block">
                            Design & Develop by
                            <a href="#" className="ms-1 underline text-orange-500">
                                Web-Bocket
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer
