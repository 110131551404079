import React from 'react'
import HeroSection from '../../Components/Home/HeroSection'
import WhatsappComponent from '../../Components/meessages/WhatsappComponent'
import Telegram from '../../Components/meessages/Telegram'
import OurGames from '../../Components/Home/OurGames'
import AboutbetBuddy from '../../Components/Home/AboutbetBuddy'
import SocialMedia from '../../Components/Home/SocialMedia'
import WhyBetBuddy from '../../Components/Home/WhyBetBuddy'
import LeaderBoard from '../../Components/Home/LeaderBoard'
import Connect from '../../Components/Home/Connect'
import Winners from '../../Components/Home/Winners'


const Home = () => {
  return (
    <div className='bg-[#0E0E0E]'>
      <section id="home">
        <HeroSection />
      </section>

      <section id="games">
        <OurGames />
        <Winners />
      </section>

      <section id="about">
        <WhyBetBuddy />
        <AboutbetBuddy />
        <SocialMedia />
      </section>

      <section id="leaderboard">
        <LeaderBoard />
        <Connect />
      </section>

      <WhatsappComponent />
      {/*       <Telegram/> */}
    </div>
  )
}

export default Home
