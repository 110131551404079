import React, { useState } from 'react';
import logo from '../../assets/Home/betbodylogo.png';
import { FaBars } from 'react-icons/fa';
import { BiMobileVibration } from "react-icons/bi";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle smooth scrolling to sections
    const handleScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setIsOpen(false); // Close the sidebar after clicking a link
    };

    return (
        <React.Fragment>
            {/* Top Section with Phone Number */}
            <div className='flex justify-end items-center bg-gradient-to-l from-[#948322] to-[#020202] pt-2 pb-1 px-4 sm:px-6 lg:px-8 text-[#ffffff] font-normal text-sm tracking-wider'>
                <div>
                    <BiMobileVibration size={20} className='mr-2' />
                </div>
                <div>
                    +91 7665666618
                </div>
            </div>

            {/* Navbar Section */}
            <nav className="bg-[#161318] text-white px-6 md:px-16 py-2 shadow-2xl">
                <div className="container mx-auto flex items-center justify-between">
                    {/* Logo */}
                    <div>
                        <img
                            src={logo}
                            alt="bet-buddy-logo"
                            className='w-auto h-20 object-contain'
                        />
                    </div>

                    {/* Links for Desktop View */}
                    <div className="hidden md:flex space-x-8 uppercase tracking-widest text-sm cursor-pointer">
                        <div onClick={() => handleScroll('home')}>Home</div>
                        <div onClick={() => handleScroll('games')}>Games</div>
                        <div onClick={() => handleScroll('about')}>About</div>
                        <div onClick={() => handleScroll('leaderboard')}>Leaderboard</div>
                    </div>

                    {/* Contact Us Button for Desktop */}
                    <div className="hidden md:block">
                        <button
                            className="text-white font-bold py-2 px-4 tracking-widest text-sm cursor-pointer border border-white"
                            onClick={() => handleScroll('contact')}
                        >
                            Contact Us
                        </button>
                    </div>

                    {/* Hamburger Menu for Mobile View */}
                    <div className="md:hidden">
                        <FaBars
                            onClick={toggleSidebar}
                            size={24}
                            className="cursor-pointer" />
                    </div>
                </div>
            </nav>

            {/* Sidebar for Mobile Menu */}
            {isOpen && (
                <div className="fixed inset-0 bg-[#161318] bg-opacity-75 z-50">
                    <div className="fixed top-0 left-0 w-64 h-full bg-[#161318] shadow-md">
                        <div className="p-4">
                            <button className="text-white text-sm tracking-widest mb-8"
                                onClick={toggleSidebar}>
                                Close
                            </button>
                            <ul className="space-y-4 text-white uppercase tracking-widest text-sm cursor-pointer">
                                <li onClick={() => handleScroll('home')}>Home</li>
                                <li onClick={() => handleScroll('games')}>Games</li>
                                <li onClick={() => handleScroll('about')}>About</li>
                                <li onClick={() => handleScroll('leaderboard')}>Leaderboard</li>
                            </ul>
                            <button
                                className="mt-8 text-white font-bold py-2 px-4 tracking-widest text-sm cursor-pointer border border-white"
                                onClick={() => handleScroll('contact')}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Navbar;
