import React from 'react'
import img1 from '../../assets/Home/people1.png'
import img2 from '../../assets/Home/people2.png'
import img3 from '../../assets/Home/people3.png'

const LeaderBoard = () => {
    return (
        <div className='px-4'>
            <h1 className='text-center text-white text-3xl md:text-5xl tracking-wide pt-14'>LEADERBOARD</h1>
            <div className='flex flex-col md:flex-row gap-5 px-4 md:px-16 mt-10'>
                <div className='relative w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 flex-col mt-14 md:mt-28'>
                    <img className='absolute -top-10 md:-top-14 w-20 md:w-auto' src={img1} alt='img' />
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Susmita</h1>
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                    <div className='w-[90%] md:w-[97%] bg-[#DDDDC4] p-4 md:p-6 absolute bottom-2 rounded-md tracking-wide text-center text-2xl md:text-3xl font-bold'>
                        $4000
                    </div>
                </div>
                <div className='relative w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 flex-col mt-14'>
                    <img className='absolute -top-10 md:-top-14 w-20 md:w-auto' src={img2} alt='img' />
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Sidharta</h1>
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                    <div className='w-[90%] md:w-[97%] bg-[#D4A041] p-4 md:p-6 absolute bottom-2 rounded-md tracking-wide text-center text-2xl md:text-3xl font-bold'>
                        $4000
                    </div>
                </div>
                <div className='relative w-full md:w-[402px] h-[20rem] md:h-[20rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 flex-col mt-14 md:mt-28'>
                    <img className='absolute -top-10 md:-top-14 w-20 md:w-auto' src={img3} alt='img' />
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>Satya</h1>
                    <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>$272,243</h1>
                    <div className='w-[90%] md:w-[97%] bg-[#BC6554] p-4 md:p-6 absolute bottom-2 rounded-md tracking-wide text-center text-2xl md:text-3xl font-bold'>
                        $4000
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard
