import React from 'react';
import buddyimg from '../../assets/Home/betbuddtgif.gif';
import { TbCircleDotFilled } from "react-icons/tb";

const AboutbetBuddy = () => {
    return (
        <div className="flex flex-col md:flex-row px-4 md:px-16 justify-between items-center py-10 mt-10">
            {/* Left Section: Text Content */}
            <div className="w-full md:w-[50%] text-white space-y-6 md:space-y-8">
                <h1 className="text-3xl md:text-5xl tracking-widest text-center md:text-left">
                    About BET BUDDY
                </h1>
                <p className="tracking-widest md:text-left text-sm md:text-base text-justify">
                    Bet Buddy is a platform offering a seamless gaming experience with a user-friendly interface, showcasing top-tier games, leaderboards, and exciting features. It ensures a secure, enjoyable environment for users to engage in betting and gaming.
                </p>

                <div className="space-y-4 ml-0 md:ml-8 text-sm md:text-base text-justify tracking-widest">
                    <div className='flex items-center'>
                        <TbCircleDotFilled size={22} className='mr-4 text-red-700' />
                        <div>
                            Bet Buddy offers secure, engaging gaming with top-tier features.
                        </div>
                    </div>

                    <div className='flex items-center'>
                        <TbCircleDotFilled size={22} className='mr-4 text-green-700' />
                        <div>
                            Bet Buddy delivers an exciting, user-friendly, and secure betting experience.
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Section: Image */}
            <div className="w-full md:w-[50%] mt-8 md:mt-0 flex items-center justify-center">
                <img src={buddyimg} alt="BET BUDDY GIF" className="w-[80%] rounded-2xl" />
            </div>
        </div>
    );
};

export default AboutbetBuddy;
