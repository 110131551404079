import React, { useState, useEffect } from 'react';
import connect from '../../assets/Home/Connect.png';

const Connect = () => {
    // Initial countdown time (set days, hours, minutes, seconds)
    const initialTime = {
        days: 40,
        hours: 0,
        minutes: 0,
        seconds: 10, // For demo purposes, reduce seconds to 10 (you can adjust as needed)
    };

    // Set countdown state
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            // Check if time is up
            if (time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
                // Reset to initial time
                setTime(initialTime);
            } else {
                // Decrease time
                if (time.seconds > 0) {
                    setTime(prevTime => ({ ...prevTime, seconds: prevTime.seconds - 1 }));
                } else if (time.minutes > 0) {
                    setTime(prevTime => ({
                        ...prevTime,
                        minutes: prevTime.minutes - 1,
                        seconds: 59,
                    }));
                } else if (time.hours > 0) {
                    setTime(prevTime => ({
                        ...prevTime,
                        hours: prevTime.hours - 1,
                        minutes: 59,
                        seconds: 59,
                    }));
                } else if (time.days > 0) {
                    setTime(prevTime => ({
                        ...prevTime,
                        days: prevTime.days - 1,
                        hours: 23,
                        minutes: 59,
                        seconds: 59,
                    }));
                }
            }
        }, 1000);

        return () => clearInterval(countdownInterval); // Clear interval on unmount
    }, [time]);

    return (
        <div className="py-10 bg-black mt-10">
            <h1 className="text-center text-white text-3xl sm:text-4xl md:text-5xl tracking-widest mt-9">
                HURRY UP! The winner will be <br /> decided when time's up
            </h1>
            <div className="flex justify-center space-x-2 sm:space-x-4 py-5 sm:py-10 flex-wrap tracking-widest">
                <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
                    {time.days}
                    <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
                        day
                    </div>
                </div>
                <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
                    {time.hours}
                    <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
                        hrs
                    </div>
                </div>
                <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
                    {time.minutes}
                    <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
                        min
                    </div>
                </div>
                <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
                    {time.seconds}
                    <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
                        sec
                    </div>
                </div>
            </div>

            <div
                className="rounded-3xl flex items-center justify-center flex-col overflow-hidden p-6 sm:p-8 mt-5 w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] mx-auto bg-cover bg-no-repeat"
                style={{
                    backgroundImage: `url(${connect})`,
                }}
            >
                <h1
                className="text-center text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl tracking-wide">
                    “To get exclusive Benefits, Connect with us”
                </h1>
                <div className="w-full sm:w-[25rem] md:w-[30rem] relative">
                    <input
                        type="text"
                        placeholder="Enter your email"
                        className="w-full py-2 px-4 sm:py-3 lg:py-4 rounded-md mt-5 sm:mt-9 border-red-500 border-2"
                    />
                </div>
            </div>

        </div>
    );
};

export default Connect;

















// import React from 'react'
// import connect from '../../assets/Home/Connect.png'

// const Connect = () => {
//     return (
//         <>
//             <div className='py-10 bg-black mt-10'>
//                 <h1 className='text-center text-white text-3xl sm:text-4xl md:text-5xl tracking-widest mt-9'>
//                     HURRY UP! The winner will be <br /> decided when time's up
//                 </h1>
//                 <div className="flex justify-center space-x-2 sm:space-x-4 py-5 sm:py-10 flex-wrap tracking-widest">
//                     <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
//                         40
//                         <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
//                             day
//                         </div>
//                     </div>
//                     <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
//                         40
//                         <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
//                             hrs
//                         </div>
//                     </div>
//                     <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
//                         40
//                         <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
//                             min
//                         </div>
//                     </div>
//                     <div className="text-white text-2xl sm:text-3xl md:text-4xl font-bold border-2 border-white p-2 sm:p-3">
//                         40
//                         <div className="text-red-500 text-sm sm:text-base md:text-lg uppercase text-center">
//                             sec
//                         </div>
//                     </div>
//                 </div>
//                 <div
//                     className='flex items-center justify-center flex-col overflow-hidden p-6 sm:p-8 mt-5 w-[90%] sm:w-[80%] md:w-[70%] mx-auto'
//                     style={{
//                         backgroundImage: `url(${connect})`,
//                         backgroundSize: "cover",
//                         backgroundRepeat: "no-repeat",
//                     }}
//                 >
//                     <h1 className='text-center text-white text-2xl sm:text-3xl md:text-4xl tracking-wide'>
//                         “To get exclusive Benefits, Connect with us”
//                     </h1>
//                     <div className='w-full sm:w-[25rem] md:w-[30rem] relative'>
//                         <input
//                             type='text'
//                             placeholder='Enter your email'
//                             className='w-full py-2 px-4 sm:py-3 rounded-md mt-5 sm:mt-9 border-red-500 border-2'
//                         />
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Connect
