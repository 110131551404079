import React from 'react';
import linebg from '../../assets/Home/linebg.png';
import img1 from '../../assets/Home/Bonus.png';
import img2 from '../../assets/Home/Uses.png';
import img3 from '../../assets/Home/Trusted.png';
import img4 from '../../assets/Home/Support.png';
import img5 from '../../assets/Home/Withdwarl.png';

const WhyBetBuddy = () => {
  return (
    <div>
      <div
        className="h-auto bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${linebg})`,
          backgroundPosition: "left",
        }}
      >
        {/* Heading Section */}
        <h1 className="text-white text-center text-3xl md:text-5xl tracking-wide mt-8 md:mt-12">
          Why Bet Buddy?
        </h1>
        <p className="tracking-widest text-white text-center px-4 md:px-0 mt-4 text-sm md:text-base">
        Why Bet Buddy? For secure, innovative, and engaging gaming with unmatched user experience and rewards.
        </p>

        {/* First Row of Boxes */}
        <div className="flex flex-wrap gap-5 justify-center px-8 md:px-16 py-6">
          <div className="relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14">
            <img
              className="absolute -top-16 w-24 h-24 md:w-32 md:h-32"
              src={img1}
              alt="Welcome Bonus"
            />
            <h1 className="text-2xl md:text-3xl text-center text-white tracking-wide">
              Welcome Bonus<br /> 10%
            </h1>
          </div>

          <div className="relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14">
            <img
              className="absolute -top-16 w-24 h-24 md:w-32 md:h-32"
              src={img2}
              alt="Active Users"
            />
            <h1 className="text-2xl md:text-3xl text-center text-white tracking-wide">
              10m+<br />
              Active Users
            </h1>
          </div>

          <div className="relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14">
            <img
              className="absolute -top-16 w-24 h-24 md:w-32 md:h-32"
              src={img3}
              alt="Trusted Platform"
            />
            <h1 className="text-2xl md:text-3xl text-center text-white tracking-wide">
              Trusted <br /> Platform
            </h1>
          </div>
        </div>

        {/* Second Row of Boxes */}
        <div className="flex flex-wrap justify-center px-8 gap-5 mx-auto">
          <div className="relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14">
            <img
              className="absolute -top-16 w-24 h-24 md:w-32 md:h-32"
              src={img4}
              alt="Support"
            />
            <h1 className="text-2xl md:text-3xl text-center text-white tracking-wide">
              24/7<br />
              Support
            </h1>
          </div>

          <div className="relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14">
            <img
              className="absolute -top-16 w-24 h-24 md:w-32 md:h-32"
              src={img5}
              alt="Withdrawal"
            />
            <h1 className="text-2xl md:text-3xl text-center text-white tracking-wide">
              Fast<br />
              Withdrawals
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyBetBuddy;























// import React from 'react';
// import linebg from '../../assets/Home/linebg.png';
// import img1 from '../../assets/Home/Bonus.png';
// import img2 from '../../assets/Home/Uses.png';
// import img3 from '../../assets/Home/Trusted.png';
// import img4 from '../../assets/Home/Support.png';
// import img5 from '../../assets/Home/Withdwarl.png';

// const WhyBetBuddy = () => {

//     return (
//         <div>
//             <div
//                 className='h-auto lg:h-[55rem]'
//                 style={{
//                     backgroundImage: `url(${linebg})`,
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: 'left',
//                 }}
//             >
//                 <h1 className='text-white text-center text-3xl md:text-5xl tracking-wide'>
//                     Why Bet Buddy?
//                 </h1>
//                 <p className='text-white text-center tracking-wide px-4 md:px-0'>
//                     Ante etiam per hymenaeos dolor vitae, libero non ornare velit morbi, imperdiet nullampellentesque ultricie eget vel tempor
//                 </p>
//                 <div className='flex flex-wrap gap-5 justify-center px-8 md:px-16 mt-10 lg:mt-20  py-6'>
//                     <div className='relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14'>
//                         <img className='absolute -top-20' src={img1} alt='Welcome Bonus' />
//                         <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>
//                             Welcome Bonus<br /> 10%
//                         </h1>
//                     </div>
//                     <div className='relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 lg:mt-0 mt-14'>
//                         <img className='absolute -top-20' src={img2} alt='Active Users' />
//                         <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>
//                             10m+<br />
//                             Active Users
//                         </h1>
//                     </div>
//                     <div className='relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2 mt-14'>
//                         <img className='absolute -top-20' src={img3} alt='Trusted Platform' />
//                         <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>
//                             Trusted <br /> Platform
//                         </h1>
//                     </div>
//                 </div>
//                 <div className='flex flex-wrap justify-center mt-20 px-8 lg:mt-16  gap-5 md:w-[67%] mx-auto space-y-20 lg:space-y-0'>
//                     <div className='relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2'>
//                         <img className='absolute -top-20' src={img4} alt='Welcome Bonus' />
//                         <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>
//                             Welcome Bonus<br /> 10%
//                         </h1>
//                     </div>
//                     <div className='relative w-full sm:w-[300px] lg:w-[402px] h-[15rem] bg-black mx-auto flex items-center justify-center rounded-md border-white border-2'>
//                         <img className='absolute -top-20' src={img5} alt='Active Users' />
//                         <h1 className='text-2xl md:text-3xl text-center text-white tracking-wide'>
//                             10m+<br />
//                             Active Users
//                         </h1>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default WhyBetBuddy;