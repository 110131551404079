import React from 'react';
import heroimg from '../../assets/Home/herosection.png';
import scroll from '../../assets/Home/scroll.png';

const HeroSection = () => {
    return (
        <React.Fragment>
            <div
                className='h-screen flex items-center justify-center flex-col'
                style={{
                    backgroundImage: `url(${heroimg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left",
                }}
            >
                <div className='text-white flex flex-col px-4 lg:px-0'>
                    <p className='text-center font-light tracking-wide text-sm sm:text-base lg:text-lg'>
                    "Bet Buddy: Where Secure Gaming Meets Excitement, Offering Top-Tier Games, Leaderboards,<br /> and an Unmatched User Experience!"
                    </p>
                    <h1 className='text-center text-3xl sm:text-5xl lg:text-7xl font-bold tracking-wider mt-10'>
                        PLAY WITH BEST <span className='text-[#FF2D36]'>SECURE</span><br /> SITES IN INDIA
                    </h1>
                </div>
                <div className='text-white font-semibold tracking-wide relative w-[8rem] sm:w-[10rem] flex items-center justify-center flex-col mt-4'>
                    <h1 className='tracking-widest absolute text-sm sm:text-base'>
                        Scroll Down
                    </h1>
                    <img src={scroll} alt='scroll' />
                </div>
            </div>
        </React.Fragment>
    );
};

export default HeroSection;
