import React from 'react';
import blackbg from '../../assets/Home/Bannerblack.png';
import img1 from '../../assets/Home/PaidOut.png';
import img2 from '../../assets/Home/Winners.png';

const Winners = () => {
  return (
    <div
      className="tracking-widest flex flex-col md:flex-row items-center h-auto w-[95%] md:w-[80%] mx-auto justify-between md:justify-around py-6 md:py-8 px-4 mb-7 rounded-2xl"
      style={{
        backgroundImage: `url(${blackbg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* First Section */}
      <div className="flex items-center justify-center space-x-4 md:space-x-5 mb-6 md:mb-0 w-full md:w-auto">
        <img
          src={img1}
          alt="Paid Out"
          className="w-14 h-14 md:w-20 md:h-20"
        />
        <div className="text-center md:text-left">
          <h1 className="text-white text-xl md:text-3xl">$15,550</h1>
          <p className="text-[#E22028] text-sm md:text-base">
            Paid Out Prize in Total
          </p>
        </div>
      </div>

      {/* Second Section */}
      <div className="flex items-center justify-center space-x-4 md:space-x-5 mb-6 md:mb-0 w-full md:w-auto">
        <img
          src={img2}
          alt="Winners"
          className="w-14 h-14 md:w-20 md:h-20"
        />
        <div className="text-center md:text-left">
          <h1 className="text-white text-xl md:text-3xl">$12,000</h1>
          <p className="text-[#E22028] text-sm md:text-base">
            Winners Total Prize
          </p>
        </div>
      </div>

      {/* Third Section */}
      <div className="flex items-center justify-center space-x-4 md:space-x-5 w-full md:w-auto">
        <img
          src={img1}
          alt="Paid Out"
          className="w-14 h-14 md:w-20 md:h-20"
        />
        <div className="text-center md:text-left">
          <h1 className="text-white text-xl md:text-3xl">$5,550</h1>
          <p className="text-[#E22028] text-sm md:text-base">
            Top Winner Prize
          </p>
        </div>
      </div>
    </div>
  );
};

export default Winners;
