import React from 'react'
import instagram from '../../assets/Home/instgram.png'
import youtube from '../../assets/Home/youtube.png'
import facebook from '../../assets/Home/facebook.png'
import twiter from '../../assets/Home/Twitter.png'

const SocialMedia = () => {
  return (
    <div className='px-4 md:px-16 my-8'>
      <h1 className='text-center text-white text-3xl md:text-5xl tracking-wide'>CHECK US ON SOCIAL MEDIA</h1>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 mt-10'>
        <div>
          <img className='mx-auto' src={instagram} alt='instagram' />
          <h1 className='text-white text-xl md:text-2xl text-center tracking-wide mt-5'>Instagram</h1>
        </div>
        <div>
          <img className='mx-auto' src={youtube} alt='youtube' />
          <h1 className='text-white text-xl md:text-2xl text-center tracking-wide mt-5'>Youtube</h1>
        </div>
        <div>
          <img className='mx-auto' src={facebook} alt='facebook' />
          <h1 className='text-white text-xl md:text-2xl text-center tracking-wide mt-5'>Facebook</h1>
        </div>
        <div>
          <img className='mx-auto' src={twiter} alt='twiter' />
          <h1 className='text-white text-xl md:text-2xl text-center tracking-wide mt-5'>Twitter</h1>
        </div>
      </div>
    </div>
  )
}

export default SocialMedia
